<template>
 <div class="container">
   <h2 class="title">Administrador</h2>
    <p>Usuarios </p>
    <!--<chat-window
      :current-user-id="currentUserId"
      :rooms="rooms"
      :messages="messages"
      :text-messages="text_messages"
      :loading-rooms="false"
      :rooms-loaded="true"
      :message-actions="messageActions"
      :messages-loaded="messagesLoaded"
      @fetch-messages="fetchMessages"
      @send-message="SendMessage"
      @typing-message="typingmessage"
    />-->
 
 </div>
</template>
<script>
  import ChatWindow from 'vue-advanced-chat'
  import 'vue-advanced-chat/dist/vue-advanced-chat.css'

  export default {
    components: {
      ChatWindow
    },
    data() {
      return {
        messagesLoaded:true,
        selectedRoom: null,
        suma: 0,
        rooms: [],
        messages: [],
        messageActions:[{
          name: 'replyMessage',
          title: 'Replicar'
        },
        {
          name: 'editMessage',
          title: 'Editar Mensaje',
          onlyMe: true
        },
        {
          name: 'deleteMessage',
          title: 'Eliminar mensaje',
          onlyMe: true
        }],
       currentUserId: 2,
       messiger_loades:true,
       text_messages: {
            ROOMS_EMPTY: 'No hay usuarios',
            ROOM_EMPTY: 'No se seleccionó ninguna conversación',
            NEW_MESSAGES: 'Nuevo mensaje',
            MESSAGE_DELETED: 'Mensaje eliminado',
            MESSAGES_EMPTY: 'No hay mensajes',
            CONVERSATION_STARTED: 'La conversación comenzó en :',
            TYPE_MESSAGE: 'Escribiendo',
            SEARCH: 'Buscar',
            IS_ONLINE: 'En linea',
            LAST_SEEN: 'ultima  connexion ',
            IS_TYPING: 'Esta escribiendo'
          }
      }
    },
     methods: {
        fetchMessages({ room, options }) {
          this.messagesLoaded = false
          //this.messages = []
          this.messages = room.mensaje
          
          //this.rooms = []

          // use timeout to mimic server fetched data
          setTimeout(() => {
              this.messagesLoaded = true
          }, 500)
      },

     SendMessage({ roomId, content, file, replyMessage, usersTag }){
              console.log('roomId:', roomId);
              /*console.log('roomId:', roomId);
               console.log('content:', content);
               console.log('file:', file);
               console.log('replyMessage:', replyMessage);
               console.log('usersTag:', usersTag);*/
              //this.suma += inicio;
              this.Guardar({
                  _id: 7890,
                  content: content,
                  senderId: this.currentUserId,
                 
                  date: '13 November',
                  timestamp: '10:20',
                })  
               console.log(this.messages);
               //this.messages = []
             
      },

      typingmessage({ message, roomId }){
             //console.log(roomId);
             for(let i = 0; i < this.rooms.length; i++) {
                      if(this.rooms[i].roomId==roomId){
                          if(message!=''){
                            this.rooms[i].typingUsers=[this.currentUserId]//aqui se va a colocar el id del usuario room
                            //console.log(this.rooms[i]);
                          }else{
                             this.rooms[i].typingUsers=[]
                          }
                      }
              }
        },

      Guardar(mensaje){
          this.messages.push(mensaje);
      },
    
    },

    created(){
      this.rooms = [{
          roomId: 1,
          roomName: 'Arnaldo',
          avatar: 'assets/imgs/people.png',
          index: '10:40',
          mensaje:[/*{
                  _id: 7890,
                  content: 'hola soy arnaldo',
                  senderId: 1323,
                  username: 'John Doe',
                  avatar: 'assets/imgs/doe.png',
                  date: '13 November',
                  timestamp: '10:20',
                  system: false,
                  saved: false,
                  distributed: false,
                  seen: false,
                  disableActions: true,
                  disableReactions: true,
               
                },*/],
          lastMessage: {
            content: 'Último mensaje recibido',
            senderId: 1,
            username: 'Arnaldo',
            timestamp: '10:40',
            /*saved: true,
            distributed: false,
            seen: false,
            new: true*/
          },
          users: [
            {
              _id: 1,
              username: 'Arnaldo',
              avatar: 'assets/imgs/doe.png',
              status: {
                state: 'online',
                lastChanged: 'today, 14:30'
              }
            },
            
          ],
          typingUsers: []
        },
        {
          roomId: 2,
          roomName: 'Claudio',
          avatar: 'assets/imgs/people.png',
          unreadCount: 4,
          index: '10:20',
          mensaje:[], //aqui van los mensajes
          lastMessage: {
            content: 'Último mensaje recibido',
            senderId: 2,
            username: 'John Doe ',
            timestamp: '10:20',
            
          },
          users: [
            {
              _id: 2,
              username: 'Claudio',
              avatar: 'assets/imgs/doe.png',
              status: {
                state: 'online',
                lastChanged: 'today, 14:30'
              }
            },
           
          ],
          typingUsers: [1] //se marca el usuario que esta escribiendo
        },]
        
      //this.messagesLoaded=true;
      console.log('hola mundo');
    }
  }
</script>
<style >
 .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1119px;
}

</style> 




