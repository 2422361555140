import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../components/auth/login.vue'
import AppLayout from '../components/admin/AppLayout.vue'
import Admin from '../components/admin/admin.vue'
import inicio from '../components/admin/inicio.vue'
import editar_usuario from '../components/admin/editar-usuario.vue'
import usuarios from '../components/admin/usuarios.vue'
import encuesta from '../components/admin/encuesta.vue'
import videos from '../components/admin/videos.vue'
import pizarra_virtual from '../components/admin/pizarra-virtual.vue'
import usuarios_admin from '../components/admin/usuariosAdmin.vue'
import perfiles from '../components/admin/perfiles.vue'
import campanas from '../components/admin/campanas.vue'
import encuesta_admin from '../components/admin/encuestasAdmin.vue'
import videos_admin from '../components/admin/videosAdmin.vue'
import configuracion from '../components/admin/configuracion.vue'
import certificado from '../components/admin/certificado.vue'
import Productos from '../components/admin/productos.vue'
import store from '../store'
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/admin')
}

const ifAuthenticated = (to, from, next) => {
 if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AppLayout,
    beforeEnter: ifAuthenticated,
    children: [
        {
          name: 'admin',
          path: 'admin',
          component: Admin,
        },
        {
          name: 'inicio',
          path: 'inicio',
          component: inicio,
        },
        {
          name: 'editar-usuario',
          path: 'editar-usuario',
          component: editar_usuario,
        },
        {
          name: 'usuarios',
          path: 'usuarios',
          component: usuarios,
        },
        {
          name: 'encuesta',
          path: 'encuestas',
          component: encuesta,
        },
        {
          name: 'videos',
          path: 'video',
          component: videos,
        },
        {
          name: 'pizarra-virtual',
          path: 'pizarra-virtual',
          component: pizarra_virtual,
        },
        {
          name: 'productos',
          path: 'productos',
          component:  Productos,
        },
         {
          name: 'usuariosAdmin',
          path: 'administrar/usuariosAdmin',
          component:  usuarios_admin,
        },
        {
          name: 'perfiles',
          path: 'administrar/perfiles',
          component:  perfiles,
        },
       
        {
          name: 'campanas',
          path: 'administrar/campanas',
          component: campanas,
        },
        {
          name: 'encuestasAdmin',
          path: 'administrar/encuestasAdmin',
          component: encuesta_admin,
        },
        {
          name: 'videosAdmin',
          path: 'administrar/Videos',
          component: videos_admin,
        },
        {
          name: 'configuracion',
          path: 'administrar/configuracion',
          component: configuracion,
        },
        {
          name: 'certificado',
          path: 'administrar/certificados',
          component: certificado,
        },



        /*{
          name: 'prueba',
          path: 'prueba',
          component:   Prueba,
        },*/
        ],
  },
]
//front/
const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? process.env.BASE_URL : process.env.BASE_URL, //process.env.BASE_URL,  //'fenix.net/Endo_fenix/frond/dist/',
  routes
})

export default router
