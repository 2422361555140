<template>
 <div class="container">
   <h2 class="title">Administrador</h2>
    <p>Editar Usuario</p>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="4" class="order-xl-2 mb-5">
            <user-card></user-card>
        </b-col>
        <b-col xl="8" class="order-xl-1">
          <edit-profile-form></edit-profile-form>
        </b-col>
      </b-row>
    </b-container>
 
 </div>
 

</template> 
<script>
  import EditProfileForm from '@/components/admin/componentes/perfil/EditProfileForm.vue';
  import UserCard from '@/components/admin/componentes/perfil/UserCard.vue';

  export default {
    components: {
      EditProfileForm,
      UserCard
    }
  };
</script> 

<style >

  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1119px;
}



</style>