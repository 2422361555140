import axios from 'axios'
var url = process.env.NODE_ENV === 'production'
   ? 'https://endomarketing.fenixpuntonet.com/apirest/'
   : 'http://localhost/fenix.net/Endo_fenix/back/'

let token = JSON.parse(localStorage.getItem('user-token'))

/*let config = JSON.stringify({

})*/
//console.log();
const RestService = axios.create({
  baseURL: url,
  headers: {
   'token': 'api rest',
   'session': token ? token : 'nada',
  // 'content-type': 'multipart/form-data'
   
  }
})

export default RestService