<template>
 <div class="container">
   <h2 class="title">Administrador</h2>
   <p>Configuracion</p>
   <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="4" class="order-xl-2 mb-5">
             imagen
        </b-col>
        <b-col xl="8" class="order-xl-1">
           formulario
           
        </b-col>
      </b-row>
    </b-container>

 </div>
</template>
<script>
import TestServices from '../../services/TestServices'

export default {
    data() {
        return {
            items: [],
            fields: [],
              infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
             },
            perPage: 10,
            currentPage: 1
        }

    },
    computed: {
       rows() {
        return this.items.length
      }
    },

     methods: {
         info(item, index, button) {
             this.infoModal.title = `Row index: ${index}`
             this.infoModal.content = JSON.stringify(item, null, 2)
             this.$root.$emit('bv::show::modal',this.infoModal.id, button)
        },

        add(item, index, button){
             this.infoModal.title = ''
             this.infoModal.content = ''
             this.$root.$emit('bv::show::modal',this.infoModal.id, button) 
        },

         AllUser(){
           TestServices.get('api/Configuracion/GetConfiguracion').then(res=>{
               //this.fields = res.fields
               //this.items = res.item
               //console.log(res.item);
            })
       }
     },
     created(){
  	    this.AllUser()
        //console.log(this.items); 

     }

}
</script>