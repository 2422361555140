<template>
  <card>
    <b-row align-v="center" slot="header" >
      <b-col cols="8">
        <h3 class="mb-0">Editar </h3>
      </b-col>
      <b-col cols="4" class="text-right">
        <a href="#!" class="btn btn-sm btn-primary">Actulizar contraseña</a>
      </b-col>
    </b-row>

    <b-form @submit.prevent="updateProfile">
      <h6 class="heading-small text-muted mb-4">Tu informacion</h6>

      <div class="pl-lg-4">
        <b-row>
          <b-col lg="6">
            <b-form-group id="input-group-3" label="Perfil:" label-for="input-3">
             <b-form-select
                id="input-3"
              
                required
                ></b-form-select>
            </b-form-group>
             
          </b-col>
          <b-col lg="6">
            <b-form-group id="input-group-3" label="membresia:" label-for="input-3">
             <b-form-select
                id="input-3"
                
                required
                ></b-form-select>
            </b-form-group>
           
          </b-col>
        </b-row>
        <b-row >
          <b-col lg="6">
             <b-form-group
            id="input-group-1"
            label="Nombre:"
            label-for="input-1"
            description="" >
            <b-form-input
                id="input-1"
               
                type="text"
                placeholder="Nombre"
                required>
                
            </b-form-input>

            </b-form-group>
            
          </b-col>
          <b-col lg="6">
             <b-form-group
            id="input-group-1"
            label="Apellido:"
            label-for="input-1"
            description="" >
            <b-form-input
                id="input-1"
               
                type="text"
                placeholder="Apellido"
                required>
                
            </b-form-input>

            </b-form-group>
          
          </b-col>
          <b-col lg="12">
             <b-form-group
            id="input-group-1"
            label="fecha de nacimiento:"
            label-for="input-1"
            description="" >
            <b-form-input
                id="input-1"
               
                type="text"
                placeholder="fecha"
                required>
                
            </b-form-input>

            </b-form-group>
          
          </b-col>
            <b-col lg="12">
             <b-form-group
            id="input-group-1"
            label="Ciudad de nacimiento:"
            label-for="input-1"
            description="" >
            <b-form-input
                id="input-1"
               
                type="text"
                placeholder="ciudad"
                required>
                
            </b-form-input>

            </b-form-group>
            
          
          </b-col>
        </b-row>
        <b-row >
          <b-col lg="6">
             <b-form-group
            id="input-group-1"
            label="Nombre:"
            label-for="input-1"
            description="" >
            <b-form-input
                id="input-1"
               
                type="text"
                placeholder="Nombre"
                required>
                
            </b-form-input>

            </b-form-group>
            
          </b-col>
          <b-col lg="6">
             <b-form-group
            id="input-group-1"
            label="Apellido:"
            label-for="input-1"
            description="" >
            <b-form-input
                id="input-1"
               
                type="text"
                placeholder="Apellido"
                required>
                
            </b-form-input>

            </b-form-group>
          
          </b-col>
          </b-row>
      </div>
     
    <!-- Description -->
     
      <div class="pl-lg-4">
        <b-form-group label="Sobre mi" label-class="form-control-label" class="mb-0" label-for="about-form-textaria">
         <!--  <label class="form-control-label">About Me</label> -->
          <b-form-textarea rows="4" value="" id="about-form-textaria" placeholder=""></b-form-textarea>
        </b-form-group>
      </div>

    </b-form>
  </card>
</template>
<script>
export default {
  data() {
    return {
      user: {
        company: 'Creative Code Inc.',
        username: 'michael23',
        email: '',
        firstName: 'Mike',
        lastName: 'Andrew',
        address: 'Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09',
        city: 'New York',
        country: 'USA',
        postalCode: '',
        aboutMe: `Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo.`
      }
    };
  },
  methods: {
    updateProfile() {
      alert('Your data: ' + JSON.stringify(this.user));
    }
  }
};
</script>
<style>
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1120px;
}

</style>
