<template>
  <div>
     <nav>
       <div class="d-flex justify-content-center">
           <ul  class="menu-desk" >
             <li v-for="(row, index) in menu" class="wow fadeInLeft"  >
                <a><span><router-link v-bind:to="row.uri">{{row.nombre}}</router-link></span></a>
             </li>
             <li  v-if="menuAdin.length!=0" class="wow fadeInLeft" ><a @click="activarsubmenu()"><span><router-link to="">Administrar</router-link></span></a>
                     <ul v-if="active_submenu" >
                        <li v-for="(row, index) in menuAdin" class="wow fadeInLeft"  >
                           <a><span><router-link v-bind:to="row.uri">{{row.nombre}}</router-link></span></a>
                       </li>
                     </ul>
             </li>
            <li class="wow fadeInLeft" ><a  @click="cerrar()"><span><router-link to="admin">salir</router-link></span></a></li>
           </ul>
     </div>

    </nav>

  	 <div class="cerrar"><p @click="cerrar()">Cerrar session</p></div>
     <div class="container">
      
       <router-view/>
     </div>
  </div>
 
</template>

<script>
export default{
 name: 'AppLayout',
  data () {
    return {
      menu:[],
      menuAdin:[],
      administrar:false,
      active_submenu:false

    }
  },

  methods:{
  	cerrar(){
  		this.$store.dispatch('AUTH_LOGOUT').then(()=>{
          this.$router.push('/')
  		}).catch(err=>{
  			this.error = true

  		});
  	},
    activarsubmenu(){
       this.active_submenu = !this.active_submenu;
    }

  },

  created(){
      let token = JSON.parse(localStorage.getItem('user-token'))
      let convert = JSON.parse(token)
      let menuall = JSON.parse(convert.data.menu)
      let menuuser =[]
      for(let i = 0; i < menuall.length; i++) {
            if(menuall[i].Administrar){
                this.menuAdin =  menuall[i].Administrar
                //console.log(menuall[i]);
                delete menuall[i].Administrar
            }

            if(menuall[i].nombre){
                  menuuser[i]= menuall[i]
             }
        }
        if(this.menuAdin.length!=0){
            this.menu =  menuuser
        }else{
            this.menu =  menuall
        }
  }


};

</script>
<style>
body{
	margin:0;
}
.menu-desk{
  margin-top:40px;
}

nav {
    position: fixed;
    z-index: 1000;
    /* right: -200px; */
    background: #852fbe;
    color: black;
    padding: 20px;
    cursor: pointer;
    
    /* margin-top: 60px; */
    height: 100vh;
    transition: all 0.5s ease-out;

}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.menu-desk li a {
    position: relative;
    color: white;
    margin: 0px 15px;
    text-align: center;
}

.cerrar{
	position:absolute;
	right:0;
	cursor:pointer;
}

</style>	