<template>
 <div class="container">
   <h2 class="title">Administrador</h2>
   <p>Encuesta admin</p>
 

<b-button @click="info('', '', $event.target)">Añadir campaña +</b-button>
     <!-- Main table element -->
     
    <b-table 
       id="my-table"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
       empty-text="No hay datos"
       show-empty
    >
     <template #cell(action)="row">
        <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
           Editar
        </b-button>
        
        <b-button size="sm">
           EL
        </b-button>
      </template>
     
    </b-table>
    <b-pagination
      v-model="currentPage"
       pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      
    ></b-pagination>

    <p class="mt-3">Página actual: {{ currentPage }}</p>
     <p class="mt-3">total de registros: {{ rows }}</p>
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal" >
         <pre>{{ infoModal.content }}</pre>
    </b-modal>

 </div>
</template> 

<script>
import TestServices from '../../services/TestServices'

export default {
    data() {
        return {
            items: [],
            fields: [],
              infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
             },
            perPage: 10,
            currentPage: 1
        }

    },
    computed: {
       rows() {
        return this.items.length
      }
    },

     methods: {
         info(item, index, button) {
             this.infoModal.title = `Row index: ${index}`
             this.infoModal.content = JSON.stringify(item, null, 2)
             this.$root.$emit('bv::show::modal',this.infoModal.id, button)
        },

        add(item, index, button){
             this.infoModal.title = ''
             this.infoModal.content = ''
             this.$root.$emit('bv::show::modal',this.infoModal.id, button) 
        },

         AllUser(){
           TestServices.get('api/Encuestas/GetEncuesta').then(res=>{
               this.fields = res.fields
               this.items = res.item
               //console.log(res.item);
            })
       }
     },
     created(){
  	    this.AllUser()
        //console.log(this.items); 

     }

}
</script>
<style >
.sr-only{
    display:none !important;
}
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), .table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(0.75rem / 2) center;
    padding-right: calc(0.75rem + 0.65em);
    /* color: black; */
    font-weight: 500;
}
.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
    margin: 0px 7px 2px 0px;
}

</style>